<template>
  <v-container>
    <v-form
      id="formadd"
      ref="formadd"
      @submit.prevent="salvar"
      :lazy-validation="true"
    >
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Nome do Item"
            placeholder=" "
            type="text"
            v-model="fields.nome"
            :rules="rules"
            :validate-on-blur="false"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Quatindade"
            :suffix="fields.qtd ? (fields.qtd > 1 ? 'Itens' : 'Item') : ''"
            placeholder=" "
            type="number"
            min="0"
            max="50"
            v-model="fields.qtd"
            :rules="rules.concat($util.formrules.range1a100)"
            :validate-on-blur="false"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            prefix="R$ "
            label="Preço"
            placeholder=" "
            type="text"
            v-model="fields.preco"
            :rules="rules"
            :validate-on-blur="false"
            v-mask="$util.formmasks.money"
          />
        </v-col>

        <v-col cols="12">
          <v-combobox
            v-model="fields.tipo"
            :rules="rules"
            :items="[
              'Medicamento com Receita',
              'Medicamento sem Receita',
              'Perfumaria'
            ]"
            placeholder="Tipo do Item"
          ></v-combobox>
        </v-col>

        <v-col cols="12">
          <v-btn
            type="submit"
            form="formadd"
            @click="salvar()"
            color="primary"
            outlined
            block
          >
            <v-icon small left>fas fa-plus</v-icon>Adicionar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { formrules } from "./../../common/formrules";
import { mapMutations } from "vuex";

export default {
  props: {
    adicionado: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      fields: {
        nome: "",
        qtd: "1",
        preco: "",
        tipo: ""
      },
      rules: []
    };
  },
  mounted() {
    this.reset();
  },
  methods: {
    ...mapMutations(["showmsg"]),
    reset() {
      this.fields = {
        nome: "",
        qtd: "1",
        preco: "",
        tipo: ""
      };
    },
    salvar() {
      this.rules.push(formrules.required);

      if (!this.$refs.formadd.validate()) {
        setTimeout(() => {
          this.rules.splice(0, this.rules.length);
          if (this.$refs.formadd) this.$refs.formadd.resetValidation();
        }, 3000);
        return;
      }

      //TODO: SAVE INFO
      /**/

      this.showmsg({ text: "Item adicionado", type: "success" });
      this.adicionarItem();
    },
    adicionarItem() {
      this.adicionar = this.fields;
    }
  },
  computed: {
    adicionar: {
      get: function() {
        return this.adicionado;
      },
      set: function(value) {
        this.$emit("update:adicionado", value);
      }
    }
  }
};
</script>

<style></style>
