<template>
  <v-dialog v-model="showdialog">
    <v-card>
      <slot />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    showdialog: {
      get: function() {
        return this.dialog;
      },
      set: function(value) {
        this.$emit("update:dialog", value);
      }
    }
  },
  methods: {
    closeDialog() {
      this.showdialog = false;
    }
  }
};
</script>
