<template>
  <v-container v-if="legendas && (newItem || item.status)">
    <v-tour
      name="myTour"
      :steps="steps"
      :options="menu.tour.tourOptions"
    ></v-tour>

    <genericDialog :dialog.sync="showAddItem">
      <protecaoAddItem v-if="showAddItem" :adicionado.sync="adicionado" />
    </genericDialog>
    <v-form
      id="formcadastro"
      ref="formcadastro"
      @submit.prevent="salvar"
      :lazy-validation="true"
    >
      <v-row
        align="start"
        align-content="start"
        justify="between"
        :style="{ 'padding-bottom': '58px' }"
      >
        <v-col
          cols="12"
          class="caption text-right font-weight-bold py-0 white--text"
        >
          <template v-if="item.info">{{ item.info.tipo }}</template>
          <template v-else-if="tipoItem">{{ tipoItem }}</template>
        </v-col>
        <v-col
          cols="12"
          class="caption text-right font-weight-bold py-0 white--text"
        >
          <template>{{ nome }}</template>
        </v-col>
        <v-col cols="12">
          <label class="white--text">Orientações</label>
          <v-textarea
            :readonly="!newItem"
            :value="newItem ? '' : fields.orientacoes"
          ></v-textarea>
        </v-col>

        <v-col v-if="isMedicamento" cols="12" class="isRelative">
          <label class="white--text ml-7">Medicamento</label>
          <v-text-field
            placeholder
            type="text"
            v-model="fields.medicamento"
            :rules="rules"
            :validate-on-blur="false"
            prepend-inner-icon="fas fa-capsules"
            persistent-hint
            hint="Nome do Medicamento"
          />

          <infoLegenda :legenda="legendas.medicamento" />
        </v-col>

        <v-col cols="12" class="isRelative">
          <label class="white--text ml-7">Tipo</label>
          <v-autocomplete
                  v-model="fields.tipo"
                  :items="tiposEvento"
                  placeholder="Tipo"
          ></v-autocomplete>
          <infoLegenda :legenda="legendas.tipoanexo" />
        </v-col>

        <v-col cols="12" class="isRelative">
          <v-menu
            ref="menuDataPrevista"
            v-model="menuDataPrevista"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <label class="white--text ml-7">Data Prevista</label>
              <v-text-field
                id="data-id"
                v-model="computedDatePrevistaFormatted"
                persistent-hint
                :hint="'Previsto para'"
                prepend-inner-icon="fas fa-calendar-alt"
                :rules="rules"
                :disabled="isTipoSolicitacaoProtocolo"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fields.dtprevista"
              no-title
              @input="menuDataPrevista = false"
              color="primary"
              :disabled="isTipoSolicitacaoProtocolo"
            ></v-date-picker>
          </v-menu>

          <infoLegenda :legenda="legendas.quando" />
        </v-col>
        <v-col cols="12" class="isRelative">
          <v-menu
                  ref="menuDataRealizada"
                  v-model="menuDataRealizada"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <label class="white--text ml-7">Data Realizada</label>
              <v-text-field
                      id="data-id"
                      v-model="computedDateRealizadaFormatted"
                      persistent-hint
                      :hint="'Quando foi realizado'"
                      prepend-inner-icon="fas fa-calendar-alt"
                      :rules="rules"
                      v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                    v-model="fields.dtexecutada"
                    no-title
                    @input="menuDataRealizada = false"
                    color="primary"
            ></v-date-picker>
          </v-menu>

          <infoLegenda :legenda="legendas.quando" />
        </v-col>
        <v-col cols="12" class="isRelative">
          <label class="white--text ml-7">Médico</label>
          <v-text-field
            type="text"
            v-model="fields.quem"
            :rules="rules"
            :validate-on-blur="false"
            prepend-inner-icon="fas fa-user-md"
            persistent-hint
            hint="Nome ou CRM do Médico que solicitou"
          />
          <infoLegenda :legenda="legendas.quem" />
        </v-col>
        <v-col cols="12" class="isRelative">
          <label class="white--text ml-7">{{
            isMedicamento || isCompra
              ? "Onde foi comprado"
              : "Onde foi realizado"
          }}</label>
          <v-text-field
            type="text"
            v-model="fields.onde"
            :rules="rules"
            persistent-hint
            :hint="
              isMedicamento || isCompra
                ? 'Farmácia, Drogaria'
                : 'Hospital, Clínica, Laboratório'
            "
            :validate-on-blur="false"
            prepend-inner-icon="fas fa-clinic-medical"
          />
          <infoLegenda :legenda="legendas.onde" />
        </v-col>

        <v-col cols="12" v-if="fields.onde && isConsulta">
          <a
            :href="$util.getUrlApp(linkUber)"
            :style="{ 'text-decoration': 'none' }"
          >
            <v-card class="primary2 white--text elevation-5 pa-1" id="uber-id">
              <v-row no-gutters>
                <v-col cols="9" class="pa-2 font-weight-bold subtitle-1">
                  Não se atrase para sua consulta. Vá de Uber e ganhe desconto
                  em sua viagem, confira!
                </v-col>
                <v-col cols="3">
                  <v-img :src="'/img/logos/logoUber.jpg'" />
                </v-col>
              </v-row>
            </v-card>
          </a>
        </v-col>

        <v-col cols="12" v-if="subitens ">
          <v-list :rounded="true" class="custom pa-0" color="transparent">
            <v-subheader>Items da Compra</v-subheader>
            <template v-for="(subitem, sindex) in subitens">
              <v-list-item
                :key="'list-item' + sindex"
                class="elevation-1"
                color="tertiary"
              >
                <v-list-item-avatar>{{ subitem.qtd }}</v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2">
                    {{ subitem.nome }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption">
                    {{ subitem.tipo }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text class="black--text subtitle-2">
                    R$
                    {{
                      parseFloat(subitem.preco)
                        .toFixed(2)
                        .toString()
                        .replace(".", ",")
                    }}
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
            </template>
            <v-list-item class="elevation-1 customTotal">
              <v-list-item-avatar class="body-2">{{
                subitensTotal.qtd
              }}</v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="subtitle-2 text-right"
                  >Subtotal</v-list-item-title
                >
                <v-list-item-title class="subtitle-2 text-right"
                  >{{ desconto }}% de desconto</v-list-item-title
                >
                <v-list-item-title
                  class="subtitle-2 font-weight-bold text-right"
                  >{{ subitensTotal.nome }}</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text
                  class="black--text subtitle-2"
                  :style="{ 'text-decoration': 'line-through' }"
                  >R$ {{ subitensTotal.preco }}</v-list-item-action-text
                >
                <v-list-item-action-text class="black--text subtitle-2"
                  >R$ -{{ subitensTotal.desconto }}</v-list-item-action-text
                >
                <v-list-item-action-text
                  class="black--text subtitle-1 font-weight-bold"
                  >R$ {{ subitensTotal.precoDesconto }}</v-list-item-action-text
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12" class="isRelative" v-if="isTipoSolicitacaoProtocolo()">
          <label class="white--text ml-7">Tipo da Solicitação</label>
          <v-combobox
                  :disabled="isTipoSolicitacaoProtocolo()"
                  v-model="fields.tipoSolicitacao"
                  :items="[
              'Protocolo',
              'Encaminhamento',
              'Solicitação',
            ]"
                  placeholder="Tipo da Solicitação"
          ></v-combobox>
          <infoLegenda :legenda="legendas.tipoanexo" />
        </v-col>
        <v-col cols="12" class="isRelative" v-else>
          <label class="white--text ml-7">Tipo da Solicitação</label>
          <v-combobox
                  :disabled="isTipoSolicitacaoProtocolo()"
                  v-model="fields.tipoSolicitacao"
                  :items="[
              'Encaminhamento',
              'Solicitação',
            ]"
                  placeholder="Tipo da Solicitação"
          ></v-combobox>
          <infoLegenda :legenda="legendas.tipoanexo" />
        </v-col>

        <v-col cols="12" class="isRelative" id="anexos-id">
          <v-file-input
            clearable
            clear-icon="fas fa-times"
            class="white--text"
            multiple
            accept=".pdf, .jpg, .jpeg, .png"
            show-size
            v-model="fields.anexo"
            label="Anexos"
            prepend-icon
            prepend-inner-icon="fas fa-paperclip"
            persistent-hint
            :hint="
              isMedicamento || isCompra
                ? 'Receita, Recibos ou Nota Fiscal'
                : 'Pedidos de exames, Recibos de Consulta e Vacinas, Prescrição Médica ou Laudo, Resultado do exame, Comprovantes ou Atestados Médicos (PDF, JPG, PNG)'
            "
          ></v-file-input>
          <infoLegenda :legenda="legendas.anexo" />
        </v-col>

        <v-col
          v-if="fields.tipoAnexo"
          cols="12"
          class="isRelative pt-0"
        >
          <v-combobox
            v-model="fields.tipoAnexo"
            :items="[
              'Pedidos de exames',
              'Recibos de Consulta e Vacinas',
              'Prescrição Médica ou Laudo',
              'Resultado do exame',
              'Comprovantes ou Atestados Médicos',
            ]"
            placeholder="Tipo de Documento Anexado"
          ></v-combobox>
          <infoLegenda :legenda="legendas.tipoanexo" />
        </v-col>

        <v-col cols="12" class="mt-5">
          <v-btn
            block
            color="primary2"
            type="submit"
            form="formcadastro"
            class="white--text"
            :loading="btnLoading"
            :disabled="btnLoading"
            >{{ newItem ? "Salvar" : "Atualizar" }}</v-btn
          >
        </v-col>

        <v-col cols="12" v-if="isConsulta">
          <a
            :href="$util.getUrlApp($util.mercadoPago)"
            :style="{ 'text-decoration': 'none' }"
          >
            <v-card class="primary elevation-5 pa-1" id="pagamento-id">
              <v-row no-gutters>
                <v-col
                  cols="9"
                  class="pa-2 font-weight-bold subtitle-1 white--text"
                  >Pague sua consulta por aqui e ganhe descontos!</v-col
                >
                <v-col cols="3">
                  <v-img :src="'/img/logos/' + $util.mercadoPago.img"></v-img>
                </v-col>
              </v-row>
            </v-card>
          </a>
        </v-col>

        <v-btn
          v-if="isCompra"
          id="addButton"
          color="primary"
          fab
          fixed
          bottom
          left
          @click="showAddItem = !showAddItem"
        >
          <v-icon medium>fas fa-plus</v-icon>
        </v-btn>

        <pickContacts>
          <v-btn id="shareButton" color="accent" fab fixed bottom right>
            <v-icon medium>fas fa-share-alt</v-icon>
          </v-btn>
        </pickContacts>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { formrules } from "./../../common/formrules";
import { mapState, mapMutations } from "vuex";

import pickContacts from "./pickContacts";
import genericDialog from "./../app/genericDialog";
import infoLegenda from "./infoLegenda";
import protecaoAddItem from "./protecaoAddItem";
import {
  findAllEventsDetailNames,
  getEntityByEventType,
  getSolicitationTypeByTipoSolicitacao,
  putEvent,
  findAllMedicalEventsAttachment
} from "../../common/healthcare_service";

export default {
  props: {
    id: {
      type: String,
    },
    perfil: {
      type: String,
    },
    index: {
      type: Number,
    },
    title: {
      type: String,
    },
    newItem: {
      type: Boolean,
      default: false,
    },
    tipoItem: {
      type: String,
    },
    nome: {
      type: String,
      default: "",
    },
    desconto: {
      type: Number,
      default: 10,
    },
    item : {}
  },
  async mounted() {
    if (this.id) {
      this.fields.medicamento = this.item.descricao;
      this.fields.tipo = this.item.info.tipo,
      this.fields.quem = this.item.info.medico
        ? this.item.info.medico
        : "";
      this.fields.onde = this.item.info.local ? this.item.info.local : "";
      this.fields.dtprevista = this.item.info.dtprevista ? this.$util.vueFormatDateFromBR(this.item.info.dtprevista) : false;
      this.fields.dtexecutada = this.item.info.dtexecutada ? this.$util.vueFormatDateFromBR(this.item.info.dtexecutada) : false;
      this.fields.orientacoes = this.item.info.orientacoes,
      //this.fields.tipoAnexo
      this.fields.tipoSolicitacao = this.item.info.tipoSolicitacao
      this.subitens = this.item.subitens;

      this.setTitle(this.item.descricao);
    }
    await this.loadTiposEvento();
    await this.loadAnexo();
    this.loadLegenda();
    this.controlTour();
  },
  watch: {
    "menu.showtour"() {
      this.controlTour();
    },
  },
  computed: {
    ...mapState(["menu"]),
    computedDatePrevistaFormatted() {
      return this.$util.vueFormatDateToBR(this.fields.dtprevista);
    },
    computedDateRealizadaFormatted() {
      return this.$util.vueFormatDateToBR(this.fields.dtexecutada);
    },
    isMedicamento() {
      return this.tipoItem == "Medicamento";
    },
    isCompra() {
      return this.tipoItem == "Compra";
    },
    isConsulta() {
      return this.$route.params.title == "Consultas";
    },
    adicionado: {
      get: function () {
        return false;
      },
      set: function (value) {
        if (value) {
          this.subitens.push(value);
          this.showAddItem = false;
        }
      },
    },
    subitensTotal() {
      var qtds = 0;
      var precos = 0;

      this.subitens.forEach((item, index) => {
        qtds += parseInt(item.qtd);
        precos +=
          parseInt(item.qtd) * this.$util.formatCurrencyFromBR(item.preco);
      });

      var desconto = precos * (10 / 100);

      return {
        qtd: qtds,
        nome: "TOTAL",
        preco: this.$util.formatCurrencyToBR(precos),
        desconto: this.$util.formatCurrencyToBR(desconto),
        precoDesconto: this.$util.formatCurrencyToBR(precos - desconto),
      };
    },
  },
  data() {
    return {
      subitens: [],
      showAddItem: false,
      fields: {
        tipo: "",
        quem: "",
        dtprevista: "",
        dtexecutada: "",
        onde: "",
        anexo: undefined,
        medicamento: "",
        orientacoes: "",
        tipoSolicitacao: "",
        tipoAnexo: ""
      },
      legendas: false,
      menuDataRealizada: false,
      menuDataPrevista: false,
      rules: [],
      btnLoading: false,
      tipoDoc: "",
      linkUber: {
        weblink: "https://www.uber.com/",
        ios: {
          id: "368677368",
        },
        android: {
          link: "https://uberm.bttn.io",
        },
      },
      steps: [
        {
          target: "#data-id",
          content: "Anote seus eventos e ganhe pontos!"
        },
        {
          target: "#uber-id",
          content: "Peça o Uber para ir ao seu evento.",
        },
        {
          target: "#anexos-id",
          content: "Anexe pedidos, receita, resultados de exame etc.",
        },
        {
          target: "#pagamento-id",
          content: "Pague por aqui.",
        },
      ],
    };
  },
  methods: {
    ...mapMutations([
      "showmsg",
      "showDefaultErrorMsg",
      "refreshReloadableKey",
      "refreshReloadableMenuKey",
      "toggleShowTour",
    ]),
    setTitle(descricao) {
      this.$route.meta.title = this.$route.params.title + " | " + descricao;
      this.refreshReloadableMenuKey();
    },
    async salvar() {
      this.rules.push(formrules.required);

      if (!this.$refs.formcadastro.validate()) {
        setTimeout(() => {
          this.rules.splice(0, this.rules.length);
          if (this.$refs.formcadastro)
            this.$refs.formcadastro.resetValidation();
        }, 3000);
        return;
      }

      this.btnLoading = true;

      let solicitationType = await getSolicitationTypeByTipoSolicitacao(this.fields.tipoSolicitacao);

      const healthcareEvent = {
        id: this.item.info.id,
        place: this.fields.onde,
        guidance: this.fields.orientacoes,
        doctorName: this.fields.quem,
        expectedDate: this.fields.dtprevista,
        realizationDate: this.fields.dtexecutada,
        solicitationType,
        medicalEventDetailName: this.fields.tipo
      }

      await putEvent(this.item.info.entity, healthcareEvent);

      this.showmsg({ text: "Cadastro salvo", type: "success" });
      this.btnLoading = false;
    },
    isTipoSolicitacaoProtocolo(){
      return this.fields.tipoSolicitacao === 'Protocolo';
    },
    controlTour() {
      if (this.menu.showtour) {
        this.$tours["myTour"].start();
      } else {
        this.$tours["myTour"].finish();
      }
    },
    async loadTiposEvento(){

      let entity = await getEntityByEventType(this.$route.params.title);

      await findAllEventsDetailNames(entity).then(result => {
        this.tiposEvento = result;
      });
    },
    async loadAnexo(){
      try {
        let medicalEventID = this.item.info.id;
        let anexo = null;
        await findAllMedicalEventsAttachment(medicalEventID).then(result => {
          anexo = result.data[0];
          this.fields.anexo = {
            name: anexo.fileName,
            size: new Blob([anexo.fileContent]).size
          };
          this.fields.tipoAnexo = anexo.description;
        });
      }catch (e) {
        // vazio
      }
    }
  },
  components: {
    pickContacts,
    infoLegenda,
    genericDialog,
    protecaoAddItem,
  },
};
</script>

<style>
.v-text-field__slot {
  padding-left: 10px;
  padding-top: 2px;
}
.v-input__slot {
  border-bottom: 1px solid #fff;
 }

.theme--light.v-input input,
.theme--light.v-input textarea {
  color: #fff !important;
}

#shareButton {
  margin-bottom: var(--v-heightBottom) !important;
}
.custom .v-text-field__details {
  display: none !important;
}
.v-text-field__details {
  margin-right: 18px !important;
}

/*  */
.isRelative {
  position: relative !important;
}
.infoIcon {
  margin-top: -18px !important;
  font-size: 16px !important;
  position: absolute !important;
  right: 12px !important;
  bottom: 10px !important;
}

#addButton {
  margin-bottom: var(--v-heightBottom) !important;
}

/*  */
.customTotal.v-list-item {
  background-color: var(--v-secondary) !important;
}
.custom .v-avatar {
  margin: 0 !important;
}
.custom .v-list-item__action {
  margin-right: 10px !important;
}
</style>
