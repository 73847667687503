<template>
  <span>
    <span @click="share()">
      <slot />
    </span>
    <div
      v-if="showField"
      :style="{ position: 'absolute', right: '12px', 'margin-top': '10px' }"
    >
      <v-form id="formshare" ref="formshare" @submit.prevent="send">
        <v-text-field
          v-model="countrytxt"
          placeholder="+55"
          flat
          dense
          solo
          autofocus
          background-color="primary"
          color="white"
          class="custom white--text"
          v-mask="$util.formmasks.countrycode"
          :style="{
            float: 'left',
            width: '52px !important',
            'margin-right': '-3px'
          }"
          :rules="countryrules"
          :validate-on-blur="false"
        ></v-text-field>
        <v-text-field
          v-model="dddtxt"
          placeholder="(  )"
          flat
          dense
          solo
          autofocus
          background-color="primary"
          color="white"
          class="custom white--text"
          v-mask="$util.formmasks.ddd"
          :style="{
            float: 'left',
            'border-radius': 0,
            width: '52px !important'
          }"
          :rules="dddrules"
          :validate-on-blur="false"
        ></v-text-field>
        <v-text-field
          v-model="phonetxt"
          placeholder="Phone Number"
          flat
          dense
          solo
          autofocus
          background-color="primary"
          color="white"
          class="custom white--text"
          v-mask="$util.formmasks.celular"
          :style="{
            float: 'left',
            width: '170px !important',
            'margin-left': '-3px'
          }"
          :rules="phonerules"
          :validate-on-blur="false"
        ></v-text-field>
        <v-btn
          depressed
          type="submit"
          form="formshare"
          color="transparent"
          :style="{ float: 'right', 'margin-left': '-60px' }"
        >
          <v-icon small color="white">fas fa-share</v-icon>
        </v-btn>
      </v-form>
    </div>
  </span>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      showField: false,
      countrytxt: "55",
      dddtxt: "",
      phonetxt: "",
      countryrules: [],
      dddrules: [],
      phonerules: []
    };
  },
  methods: {
    ...mapMutations(["showmsg"]),
    msgNotPossible() {
      this.showmsg({
        text: "Não é possivel compartilhar por este dipositivo.",
        type: "red"
      });
    },
    share() {
      if ("share" in navigator) {
        this.select_share();
      } else {
        this.msgNotPossible();
      }
    },
    async select_share() {
      const shareData = {
        title: "Eu Protegido",
        text:
          "Portal: www.sitelaboratorio.com. Usuario: login. Senha: password.",
        url: "https://euprotegido.firecodeit.net/"
      };

      try {
        await navigator.share(shareData);
      } catch (error) {
        this.msgNotPossible();
      }
    },
    shareOLD() {
      const supported = "contacts" in navigator && "ContactsManager" in window;
      if (supported) {
        this.pick();
      } else {
        this.showField = !this.showField;
      }
    },
    async pick() {
      this.dddtxt = "";
      this.phonetxt = "";

      this.showField = false;

      const props = ["name", "tel"];
      const opts = { multiple: false };
      try {
        const contacts = await navigator.contacts.select(props, opts);
        if (contacts.length) {
          if (contacts[0].tel[0]) {
            var phone = this.$util.formmasks.unmask(contacts[0].tel[0]);

            if (phone.substr(0, 1) == "0") phone = phone.substr(1);

            if (phone.length == 12 || phone.length == 13) {
              this.countrytxt = phone.substr(0, 2);
              phone = phone.substr(2);
            }

            if (phone.length == 10 || phone.length == 11) {
              this.dddtxt = phone.substr(0, 2);
              phone = phone.substr(2);
            }

            this.phonetxt = phone;
            this.showField = true;
          } else
            this.showmsg({
              text: "O contato não possui número vinculado",
              type: "yellow"
            });
        }
      } catch (error) {
        this.showmsg({
          text: "Não foi possivel acessar as inforações do contato",
          type: "red"
        });
      }
    },
    validate() {
      var isvalid = false;

      if (this.validateCountry() && this.validateDDD() && this.validatePhone())
        isvalid = true;

      return isvalid;
    },
    validateCountry() {
      var isvalid = true;

      this.countryrules.push(this.$util.formrules.required);
      this.countryrules.push(this.$util.formrules.countrycode);

      if (!this.$refs.formshare.validate()) {
        setTimeout(() => {
          this.countryrules.splice(0, this.countryrules.length);
          if (this.$refs.formshare) this.$refs.formshare.resetValidation();
        }, 4000);
        isvalid = false;
      }

      return isvalid;
    },
    validateDDD() {
      var isvalid = true;

      this.dddrules.push(this.$util.formrules.required);
      this.dddrules.push(this.$util.formrules.ddd);

      if (!this.$refs.formshare.validate()) {
        setTimeout(() => {
          this.dddrules.splice(0, this.dddrules.length);

          if (this.$refs.formshare) this.$refs.formshare.resetValidation();
        }, 4000);
        isvalid = false;
      }

      return isvalid;
    },
    validatePhone() {
      var isvalid = true;

      this.phonerules.push(this.$util.formrules.required);
      this.phonerules.push(this.$util.formrules.celular);

      if (!this.$refs.formshare.validate()) {
        setTimeout(() => {
          this.phonerules.splice(0, this.phonerules.length);

          if (this.$refs.formshare) this.$refs.formshare.resetValidation();
        }, 4000);
        isvalid = false;
      }

      return isvalid;
    },
    send() {
      if (!this.validate()) return;

      const cel = this.$util.formmasks.unmask(
        this.countrytxt + this.dddtxt + this.phonetxt
      );
      this.sendWAPP(cel);
    },
    sendWAPP(cel) {
      const msg =
        "Portal: www.sitelaboratorio.com. Usuario: login. Senha: password.";
      this.$util.linkNewTab("https://wa.me/" + cel + "?text=" + msg);
    }
  }
};
</script>

<style>
.custom.v-input input::placeholder {
  color: white !important;
  opacity: 0.5;
}
.custom.v-input input {
  color: white !important;
}
.custom.v-input .v-text-field__details {
  bottom: -28px !important;
  left: -10px !important;
  position: absolute !important;
  width: 100% !important;
}
.custom.v-input .v-messages__message {
  background-color: white !important;
  padding: 4px !important;
  font-size: 14px !important;
  font-weight: bold !important;
}
</style>
