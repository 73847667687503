<template>
  <span v-if="legenda">
    <template v-if="legenda.img">
      <v-icon
        color="grey"
        class="infoIcon"
        @click="
          $util.link('fullScreenImg', {
            title: 'Informações',
            url: legenda.img,
          })
        "
        >fas fa-info-circle</v-icon
      >
    </template>
    <v-tooltip v-else bottom right color="primary" max-width="50%">
      <template v-slot:activator="{ on }">
        <v-icon color="white" class="infoIcon" v-on="on"
          >fas fa-info-circle</v-icon
        >
      </template>
      <span class="white--text">{{ legenda.descricao }}</span>
    </v-tooltip>
  </span>
</template>

<script>
export default {
  props: {
    legenda: {
      type: Object,
    },
  },
};
</script>

<style>
.infoIcon {
  margin-top: -18px !important;
  font-size: 16px !important;
  position: absolute !important;
  right: 12px !important;
  bottom: 10px !important;
}

.v-messages__message {
  color: #fff;
}
.theme--light.v-label{
  color: #fff !important;
}
.v-icon.v-icon {
  color: #fff !important;
}
</style>
