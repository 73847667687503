var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('span',{on:{"click":function($event){return _vm.share()}}},[_vm._t("default")],2),(_vm.showField)?_c('div',{style:({ position: 'absolute', right: '12px', 'margin-top': '10px' })},[_c('v-form',{ref:"formshare",attrs:{"id":"formshare"},on:{"submit":function($event){$event.preventDefault();return _vm.send.apply(null, arguments)}}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.$util.formmasks.countrycode),expression:"$util.formmasks.countrycode"}],staticClass:"custom white--text",style:({
          float: 'left',
          width: '52px !important',
          'margin-right': '-3px'
        }),attrs:{"placeholder":"+55","flat":"","dense":"","solo":"","autofocus":"","background-color":"primary","color":"white","rules":_vm.countryrules,"validate-on-blur":false},model:{value:(_vm.countrytxt),callback:function ($$v) {_vm.countrytxt=$$v},expression:"countrytxt"}}),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.$util.formmasks.ddd),expression:"$util.formmasks.ddd"}],staticClass:"custom white--text",style:({
          float: 'left',
          'border-radius': 0,
          width: '52px !important'
        }),attrs:{"placeholder":"(  )","flat":"","dense":"","solo":"","autofocus":"","background-color":"primary","color":"white","rules":_vm.dddrules,"validate-on-blur":false},model:{value:(_vm.dddtxt),callback:function ($$v) {_vm.dddtxt=$$v},expression:"dddtxt"}}),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.$util.formmasks.celular),expression:"$util.formmasks.celular"}],staticClass:"custom white--text",style:({
          float: 'left',
          width: '170px !important',
          'margin-left': '-3px'
        }),attrs:{"placeholder":"Phone Number","flat":"","dense":"","solo":"","autofocus":"","background-color":"primary","color":"white","rules":_vm.phonerules,"validate-on-blur":false},model:{value:(_vm.phonetxt),callback:function ($$v) {_vm.phonetxt=$$v},expression:"phonetxt"}}),_c('v-btn',{style:({ float: 'right', 'margin-left': '-60px' }),attrs:{"depressed":"","type":"submit","form":"formshare","color":"transparent"}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("fas fa-share")])],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }